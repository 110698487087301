export const ADD_CARD = 'ADD_CARD'
export const DELETE_CARD = 'DELETE_CARD'
export const ACTIVATE_CARD_EDIT = 'ACTIVATE_CARD_EDIT'
export const EDIT_CARD = 'EDIT_CARD'
export const TRIGGER_ADD_CARD_STATE = 'TRIGGER_ADD_CARD_STATE'
export const ADD_BATTLECARD = 'ADD_BATTLECARD'
export const DELETE_BATTLECARD = 'DELETE_BATTLECARD'
export const TRIGGER_SECTION_STATE = 'TRIGGER_SECTION_STATE'
export const MODIFY_BATTLECARD_TITLE = 'MODIFY_BATTLECARD_TITLE'
export const CHANGE_SELECTED_BATTLECARDS = 'CHANGE_SELECTED_BATTLECARDS'
export const CLEAR_SELECTED_BATTLECARDS = 'CLEAR_SELECTED_BATTLECARDS'
export const ADD_BATTLECARD_TO_SECTION_AND_SELECTED_BATTLECARDS = 'ADD_BATTLECARD_TO_SECTION_AND_SELECTED_BATTLECARDS'
export const CHANGE_BLUE_HEADER_VALUE = 'CHANGE_BLUE_HEADER_VALUE'
export const CHANGE_RED_HEADER_VALUE = 'CHANGE_RED_HEADER_VALUE'
export const READ = 'fetch all items'
export const FETCH_ITEMS_BEGIN = "begin fetching items";
export const FETCH_ITEMS_SUCCESS = "Items fetched successfully";
export const FETCH_ITEMS_FAILURE = "Failed to fetch items";

/* export const TOGGLE_TODO = 'TOGGLE_TODO'
export const SET_FILTER = 'SET_FILTER'
export const FILTER_ALL = 'all'
export const FILTER_COMPLETED = 'completed'
export const FILTER_INCOMPLETE = 'incomplete'
export const Filters = [FILTER_ALL, FILTER_COMPLETED, FILTER_INCOMPLETE] */